<template lang="html">
  <div class="component-wrapper">
      <div v-if="!emailSent" class="forgot-password-div">
          <h1 class="mb-3">Forgot your password?</h1>
          <p>Enter the email you registered with and we'll send you an email with instructions on how to reset your password.</p>
          <b-form method="post">
              <b-input v-model="email" class="mb-2 forgot-password-input"
                       placeholder="Email"
                       type="text">
              </b-input>
              <button id="forgot-email-button" @click.prevent="sendNewPasswordMail">
                  <b>Send</b>
              </button>
          </b-form>
      </div>
      <div v-else class="forgot-password-div">
          <p>We've sent you a link with instructions on how to reset your password.</p>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            email: '',
            emailSent: false
        }
    },
    methods: {
        sendNewPasswordMail() {
            this.$http.post(`${this.$store.state.endpoints.baseAuthUrl}password_reset/`, {'email': this.email}).then(() => {
                this.emailSent = true
            }).catch((error) => {
                this.emailSent = true
                return error
            })
        }
    }
}
</script>

<style lang="css" scoped>
.forgot-password-div {
    padding: 2em;
    border-radius: 1em;
    width: 66%;
    box-shadow: 3px 3px 3px 5px var(--darkBackground);
    color: white;
    height: 50vh;
}
.forgot-password-input {
    width: 33%;
}
@media (max-width: 792px) {
    .forgot-password-input {
        width: 100%;
    }
    .forgot-password-div {
        width: 100%;
    }
}
#forgot-email-button {
    background: white;
    box-shadow: 3px 3px 3px var(--darkBackground),
                        -1px -1px 3px var(--background);
    border-radius: 1em;
    width: 8em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--darkBackground);
    align-self: center;
    padding: 0.5em;
    border: none;
}

#forgot-email-button:active {
    transform: translateY(4px);
    -webkit-transition-duration: 0.2s; /* Safari */
    transition-duration: 0.2s;
}

#forgot-email-button:hover {
    color: white;
    background: var(--hoverColor);
}

</style>
